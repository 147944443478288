
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator'

import { RequestPaging, throttle } from '@/utils/util'

import 'highlight.js/lib/common';
import hljsVuePlugin from "@highlightjs/vue-plugin";

@Component({
    components: {
        highlightjs: hljsVuePlugin.component
    }
})
export default class Detail extends Vue {
    @Inject('visible') visible!: any
    $refs!: { table: any }
    @Prop() code!: any
    @Prop() goods!: any
    @Prop({ default: 'single' }) type!: 'multiple' | 'single'
    @Prop() limit!: number
    @Prop({ default: false }) isSpec!: boolean
    @Prop({ default: () => { } }) params!: Record<any, any>
    // 是否展示虚拟商品
    @Prop({ default: false }) showVirtualGoods?: boolean
    searchData = {
        name: '',
        is_distribution: '',
        is_discount: ''
    }
    pager = new RequestPaging()
    selectedObj: any = {}
    activeTab = 'index0'

    // @Watch('visible', { deep: true, immediate: true })
    // visibleChange(val: any) {
    //     if (val.val) {
    //         this.getList()
    //     }
    // }


}
