import request from '@/plugins/axios'


// 订单列表
// export const apiOrderLists = (params: any) => request.get('/order.set_meal_order/lists', params)

// 订单备注
// export const apiOrderRemarks = (params: any) => request.post('/order.set_meal_order/remark', params)


// 代码生成已选数据表列表接口
export function generateTable(params: any) {
    return request.get('/dev.generator/generateTable', params)
}

// 数据表列表接口
export function dataTable(params: any) {
    return request.get('/dev.generator/dataTable', {params})
}

//选择要生成代码的数据表
export function selectTable(params: any) {
    return request.post('/dev.generator/selectTable', params)
}

// 已选择的数据表详情
export function tableDetail(params: any) {
    return request.get('/dev.generator/detail', {params})
}

//同步字段
export function syncColumn(params: any) {
    return request.post('/dev.generator/syncColumn', params)
}

//删除已选择的数据表
export function generateDelete(params: any) {
    return request.post('/dev.generator/delete', params)
}

//编辑已选表字段
export function generateEdit(params: any) {
    return request.post('/dev.generator/edit', params)
}

//预览代码
export function generatePreview(params: any) {
    return request.post('/dev.generator/preview', params)
}

//生成代码
export function generateCode(params: any) {
    return request.post('/dev.generator/generate', params)
}

//获取模型
export function getModels() {
    return request.get('/dev.generator/getModels')
}
